* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  font-family: 'Poppins', sans-serif;
}

body > * {
  max-width: 100%;
  max-height: 100%;
}

@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lobster Regular'), local('Lobster-Regular'), url(./fonts/Lobster/Lobster-Regular.ttf) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(./fonts/Poppins/Poppins-Regular.ttf) format('woff2');
}

/* Stop tabs/pills from being "display: None" when unselected as this
   breaks the initialisation of literallycanvas */
.tab-content > .tab-pane:not(.active),
.pill-content > .pill-pane:not(.active) {
  display: block;
  height: 0;
  overflow-y: hidden;
}

/* Make the height of the modals dynamic */
.ReactModal__Content {
  inset: 0px !important;
  position: relative !important;
  margin: 2vh 2vw;
}

button {
  -webkit-appearance: none !important;
  border-radius: 0 !important;
  color: white;
  background-image: none !important;
  text-transform: uppercase;
}

button:active:focus, button:focus, button:active, .button:active:focus, .button:focus, .button:active {
  outline: solid 1px lightgrey;
}

input {
  border-radius: 0 !important;
}

.alert {
  border-radius: 0 !important;
}
